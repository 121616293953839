.action-button {
  @apply text-xs sm:text-xl text-white cursor-pointer;
  kbd {
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: underline;
  }

  &:hover {
    color: $ucat-warning-yellow;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
  &.next-question {
    margin-right: 0px;
  }
  &.scratch-pad {
    img {
      height: 22px;
      margin-right: 7px;
      position: relative;
      top: 4px;
    }

    img.scratch-pad-active-icon {
      display: none;
    }

    &:hover {
      img.scratch-pad-default-icon {
        display: none;
      }

      img.scratch-pad-active-icon {
        display: inline;
      }
    }
  }

  /* Display the active state icon only on hover */
  &.timing-trainer {
    img {
      height: 22px;
      margin-right: 7px;
      position: relative;
      top: 4px;
    }
    &-disabled {
      pointer-events: none;
    }

    img.timing-trainer-active-icon {
      display: none;
    }

    &:hover {
      img.timing-trainer-default-icon {
        display: none;
      }

      img.timing-trainer-active-icon {
        display: inline;
      }
    }
  }

  &.screenshot {
    padding: 0 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      height: 20px;
    }

    span.text {
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}

.sprite-icon {
  max-width: 100%;
  background-image: url('../assets/_all-sprites.png');
  vertical-align: middle;
  margin-right: 5px;

  &.sprite-over-white {
    background-color: #fff;
    margin-top: -4px;
  }

  &.sprite-calculator {
    background-position: 0 0;
  }

  &.sprite-question-number-indicator {
    background-position: 0px -400px;
    margin-right: 0; /* cancel out the parent margin */
  }

  &.sprite-end {
    background-position: 0px -300px;
  }

  &.sprite-review-screen {
    background-position: -50px -500px;
  }

  &.sprite-previous {
    background-position: 0px -200px;
  }

  &.sprite-next {
    background-position: 0px -150px;
    margin-left: 5px;
  }

  &.sprite-review-all {
    background-position: -50px -150px;
  }

  &.sprite-review-incomplete {
    background-position: -50px -200px;
  }

  &.sprite-review-flagged {
    background-position: -50px -350px;
  }

  &.sprite-accordion-open {
    padding: 2px;
    background-position: -50px -400px;
  }

  &.sprite-accordion-close {
    padding: 2px;
    background-position: -50px -450px;
  }

  &.sprite-flag-on-white {
    background-position: -50px -250px;
  }

  &.sprite-flag-on-white-selected {
    background-position: -50px -300px;
  }

  &.sprite-timer {
    background-position: 0 74.98%;
    background-size: 228.052632%;
  }

  &.sprite-flag {
    background-position: 0 -50px;
  }

  &.sprite-flag-selected {
    background-position: 0 -100px;
  }

  &.sprite-close-modal {
    background-position: 0 -250px;
  }
}

.sprite-info-icon {
  background-image: url('../assets/_all-sprites.png');
  background-position: -50px -48px;
}

.sprite-error-icon {
  background-image: url('../assets/_all-sprites.png');
  background-position: -50px -98px;
}

.instruction-icon {
  margin-right: 5px;
  padding-left: 25px;
  padding-right: 0px;
  background-image: url('../assets/_all-sprites-2.png');
  background-position-x: left;
  background-position-y: -616px;
  background-repeat: no-repeat;
  &:hover {
    background-position-y: -661px;
  }
}

.navigation-icon {
  margin-right: 5px;
  padding-left: 25px;
  padding-right: 0px;
  background-image: url('../assets/_all-sprites-2.png');
  background-position-x: left;
  background-position-y: -1704px;
  background-repeat: no-repeat;
  &:hover {
    background-position-y: -1747px;
  }
}

@media screen and (max-width: 640px) {
  .navigation-icon {
    height: 20px;
    background-position-y: -1708px;
    &:hover {
      background-position-y: -1751px;
    }
  }
}

.draggable-navigation-icon {
  width: 20px;
  height: 28px;
  background-image: url('../assets/_all-sprites-2.png');
  background-position-y: -1705px;
  background-repeat: no-repeat;
}

.flag-icon {
  display: inline-block;
  width: 35px;
  height: 26px;
  background-repeat: no-repeat;
  background-image: url('../assets/_all-sprites-2.png');
  background-position: left -2225px;
}

.sprite-all-sprite,
//.sprite-info-icon,
.sprite-question-mark,
//.sprite-question-number-indicator,
//.sprite-end-exam,
//.sprite-flag-selected,
//.sprite-flag,
//.sprite-review-all,
//.sprite-review-flagged,
//.sprite-timer,
//.sprite-flag-on-white-selected,
//.sprite-flag-on-white,
.sprite-review-screen,
.sprite-accordion-close,
.sprite-accordion-open,
.sprite-close-modal
//.sprite-calculator,
//.sprite-next,
//.sprite-previous,
//.sprite-review-incomplete
{
  max-width: 100%;
  background-image: url('../assets/_all-sprites.png');
  vertical-align: middle;
}
