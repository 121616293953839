// Fonts
@import url('https://fonts.googleapis.com/css?family=Open Sans:300,400,500,600,700,300italic');

// Variables
@import 'variables';

// Common
@import '../simulated-platform-common/common';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

.exam-container {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  overflow: hidden;
  max-height: 100vh;
  @include no-select;
}

p {
  margin-top: 0;
  @apply leading-tight;
}

div.exam-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

div.simulated-platform-bar {
  color: white;
}

div.main-area {
  flex: 1; /* 1 and it will fill whole space left if no flex value are set to other children */
  display: flex; /* allows children to be flex */
  background-color: #fff;
  overflow: auto;
}

.me-header-fixed {
  @apply sticky top-0;
}

div.question-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
  position: relative;
  font-size: 17px;
  @media screen and (max-width:640px) {
    font-size: 13px;
  }
  div.question-side {
    width: 100%;
    padding: 20px;
    overflow: auto;
    height: calc(100vh - 125px);
  }
  div.question-side p {
    margin-bottom: 16px;
  }
  @media screen and (min-width: $ucat-platform-nowrap-width) {
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 1200px) {
    // height: 84vh;
    overflow: auto;

    div.question-side {
      height: fit-content;
    }
  }
  @media screen and (max-width: 360px) {
    height: auto;
    overflow: auto;

    div.question-side {
      height: fit-content;
    }
  }
  @media screen and (max-width: 600px) {
    div.question-image-responsive {
      p > img {
        width: 100%;
      }
    }
  }

  @import 'multiple-choice-question';
  @import 'drag-and-drop-question';
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}
.draggable-position-class {
  top: calc(50vh - 218px);
  left: calc(50vw - 120px);
}
.draggable-navigation-position-class {
  top: 20%;
  left: 25%;
}
@media screen and (max-width: 1024px) {
  .draggable-navigation-position-class {
    top: 20%;
    left: 10%;
  }
}

@media screen and (max-width: 768px) {
  .draggable-navigation-position-class {
    top: 15%;
    left: 10%;
  }
}

/* styles for mobile devices */
@media screen and (max-width: 480px) {
  .draggable-navigation-position-class {
    top: 15%;
    left: 5%;
  }
}
.exam-hover-yellow-text:hover {
  color: #ff0;
  img {
    background-color: #ff0;
  }
}
.navigation-table-hover-yellow:hover {
    background-color: rgb(255, 255, 0, 0.5);
}
.navigation-selected-tr {
  background-color: rgb(255, 255, 0, 0.5);
}

.scratchpad-icon-hover {
  .scratchpad-icon-active {
    display: none;
  }
}
.scratchpad-icon-hover:hover {
  .scratchpad-icon {
    display: none;
  }
  .scratchpad-icon-active {
    display: block;
  }
}
@import 'action-buttons';
@import 'dialogue';
@import 'draggable';
@import 'staff-tools';
@import 'rating.scss';
@import 'multiple-choice-question';
@import 'calculator';
