.rating-container {
    display: flex;
    align-items: center;
    font-weight: bold;

    .rating-star {
        cursor: pointer;
        filter: invert(52%) sepia(14%) saturate(2%) hue-rotate(1deg)
            brightness(94%) contrast(95%);
    }

    .rating-star-color-change {
        cursor: pointer;
        filter: invert(82%) sepia(33%) saturate(3065%) hue-rotate(0deg)
            brightness(106%) contrast(104%);
    }
}
