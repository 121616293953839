// @font-face {
//   font-family: 'museo-sans';
//   src: url('./font/MuseoSans-300.otf');
// }

.focus-user:hover {
  animation: focus-user 1s 1 ease;
}
@keyframes focus-user {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.exam-html {
  overflow: hidden;
  // width: 100vw;
}
.exam-body {
  height: 100%;
  width: 100vw;
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@layer base {
  img {
    @apply inline-block;
  }
}
.dashboard {
  background-color: #e5e5e5;
  @apply min-w-full min-h-screen dark:bg-dark-700;
}

.btn-primary {
  @apply bg-red-500 rounded-md text-white text-xs uppercase hover:bg-red-700 focus:bg-red-500 border border-red-500 hover:border-red-700;
  &.btn-outlined {
    @apply border border-red-500 bg-white dark:bg-red-500 dark:bg-opacity-10 dark:text-white text-black hover:bg-red-700 dark:hover:bg-red-700  hover:border-red-700 hover:text-white;
  }
  &.btn-outline-none {
    @apply text-red-500 border-red-500 bg-white border-none text-sm capitalize hover:text-red-700 hover:bg-transparent focus:border-red-500 rounded-none;
  }
}
.braintree-heading {
  @apply dark:text-white;
}
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  position: relative;
  background: linear-gradient(
    to right,
    #f2473a 0%,
    #f2473a 14%,
    #e0e0e0 14%,
    #e0e0e0 100%
  );
  outline: none;
  opacity: 0.9;
  border-radius: 2px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  transition: background 450ms ease-in;
}
.light-shadow-dashboard {
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.light-shadow-all-question {
  background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.light-shadow-left-question {
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.light-shadow-right-question {
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.modified-radio-button {
  -webkit-appearance: radio !important;
  width: 1.2rem !important;
}
.dark-shadow-dashboard {
  background: linear-gradient(270deg, #222222 0%, rgba(34, 34, 34, 0) 100%);
}
.dark-shadow-all-question {
  background: linear-gradient(360deg, #222222 0%, rgba(255, 255, 255, 0) 100%);
}
.dark-shadow-left-question {
  background: linear-gradient(90deg, #222222 0%, rgba(255, 255, 255, 0) 100%);
}
.dark-shadow-right-question {
  background: linear-gradient(270deg, #222222 0%, rgba(255, 255, 255, 0) 100%);
}
.slider:hover {
  opacity: 1;
}
.slider::-moz-range-progress {
  @apply bg-red-500;
}
input[type='range']::-webkit-progress-value {
  @apply bg-red-500 appearance-none;
}
input[type='radio']:checked.hpat-modified-radio-button {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='gray' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");
  @apply text-white border border-gray-700;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  border-radius: 4px;
  background: url('./button.png');
  @apply h-6 w-4 appearance-none cursor-pointer;
}
.slider::-moz-range-thumb {
  -webkit-appearance: none;
  position: relative;
  border-radius: 4px;
  background: url('./button.png');
  @apply h-6 w-4 appearance-none cursor-pointer;
}
.rdtPicker {
  @apply dark:bg-dark-500 dark:text-white dark:border-dark-400;
}

// .emTeya {
//   all: none !important;
//   position: relative;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-flex-direction: column;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -webkit-justify-content: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   width: max-content;
//   height: 100%;
//   overflow: hidden;
//   min-height: 48px !important;
//   background-color: transparent !important;
//   box-sizing: border-box;
//   border-radius: 6px;
//   color: #202020 !important;
//   @apply dark:text-white;
// }
// .eElzlq {
//   @apply hidden;
// }
// .ecCRdp {
//   @apply hidden;
// }
// .kBCwrU {
//   display: none !important;
// }
// .dRmQAW {
//   display: none !important;
// }
// .hEjUYU {
//   position: absolute !important;
//   left: 14px !important;
//   margin-right: -2rem !important;
//   top: calc(100% - 50px) !important;
//   right: calc(100% - 66px) !important;
//   font-family: Menlo, monospace !important ;
//   font-size: 14px !important;
//   text-shadow: none !important;
//   background: white !important;
//   color: black !important;
//   padding: 6px 12px !important;
//   border-radius: 4px !important;
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center !important;
// }
.jmINMA {
  height: 6px !important;
  width: 6px !important;
}
kbd {
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
}
div.paragraph-mcq p {
  line-height: normal;
  @apply block mb-4 mx-0;
}
.full-question-container {
  table {
    @apply border-collapse border border-gray-900;
  }
  td {
    @apply border border-gray-900;
  }
  p {
    @apply block mb-4 mx-0;
  }
}
.question-container ul {
  display: block;
  list-style-type: disc;
  // margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.question-container ol {
  display: block;
  list-style-type: decimal;
  // margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.qtext p {
  line-height: normal;
  @apply block mb-4 mx-0;
}
.exam-container h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.exam-container ul {
  display: block;
  list-style-type: disc;
  // margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.exam-container ol {
  display: block;
  list-style-type: decimal;
  // margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.exam-container input[type='radio' i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 0px 3px 0px 5px;
  padding: initial;
  border: initial;
}
.multiline-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.event-multiline-overflow {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.tutor-multiline-overflow {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.custom-scroll {
  &::-webkit-scrollbar {
    @apply w-3;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-clip-content border-4 border-transparent border-solid rounded-2xl bg-gray-200 dark:bg-dark-200;
  }

  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400 dark:bg-dark-300;
  }
}

.html-faq-container {
  p {
    @apply block mb-3 mx-0;
    a {
      word-break: break-word;
      @apply text-blue-500 cursor-pointer #{!important};
    }
  }
  a {
    word-break: break-word;
    @apply text-blue-500 cursor-pointer #{!important};
  }
}

.html-renderer-container {
  .white-link-text {
    @apply text-white #{!important};
  }
  u {
    text-decoration-color: #f2473a;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
  ol {
    @apply block list-decimal pl-10 my-4 mx-0;
  }
  ul {
    @apply block list-disc my-4 pl-10 mx-0;
  }
  li {
    @apply list-item;
  }
  table {
    @apply table border-separate border border-gray-900 dark:border-white;
    span {
      @apply dark:text-white bg-transparent #{!important};
    }
  }
  tbody {
    @apply table-row-group align-middle border border-gray-900 dark:border-white;
  }
  p {
    @apply block my-4 mx-0;
    a {
      word-break: break-word;
      @apply text-blue-500 cursor-pointer #{!important};
      span {
        overflow-wrap: break-word;
        @apply dark:text-blue-500 bg-transparent #{!important};
      }
    }
    span {
      @apply dark:text-white bg-transparent #{!important};
      a {
        @apply text-blue-500 cursor-pointer #{!important};
      }
    }
    .override-hpat-unit-text {
      background: yellow !important;
    }
    td {
      @apply dark:text-white bg-transparent #{!important};
    }
    strong {
      @apply dark:text-white bg-transparent #{!important};
    }
    em {
      @apply dark:text-white bg-transparent #{!important};
    }
  }

  td {
    @apply table-cell border border-gray-900 dark:border-white;
  }
  tfoot {
    @apply table-footer-group align-middle border border-gray-900 dark:border-white;
  }
  th {
    @apply table-cell font-semibold text-center border border-gray-900 dark:border-white;
  }
  thead {
    @apply table-header-group align-middle border border-gray-900 dark:border-white;
  }
  tr {
    @apply table-row border border-gray-900 dark:border-white;
  }
  a {
    @apply text-blue-500 cursor-pointer outline-none;
  }
  h1 {
    @apply text-3xl font-semibold;
  }
  h2 {
    @apply text-2xl font-semibold;
  }
  h3 {
    @apply text-xl font-semibold;
  }
  h4 {
    @apply text-base font-semibold;
  }
  h5 {
    @apply text-sm font-semibold;
  }
  h6 {
    @apply text-xs font-semibold;
  }
}

.border-table {
  border-spacing: 0;
  @apply border-separate;
  th {
    @apply border-r border-gray-300 dark:border-dark-400;
  }
  .border-bottom {
    border-right: 0px !important;
  }
  td {
    @apply border-r border-b border-gray-300 dark:border-dark-400;
  }
  th:first-child,
  td:first-child {
    @apply border-l border-gray-300 dark:border-dark-400;
  }
}
.rounded-border-table {
  border-spacing: 0;
  @apply border-separate;
  th {
    @apply border-t border-r border-b border-gray-300 dark:border-dark-400;
  }
  .border-bottom {
    border-right: 0px !important;
  }
  td {
    @apply border-r border-b border-gray-300 dark:border-dark-400;
  }
  th:first-child,
  td:first-child {
    @apply border-l border-gray-300 dark:border-dark-400;
  }
  tr:first-child th:first-child {
    @apply rounded-tl-md;
  }
  tr:first-child th:last-child {
    @apply rounded-tr-md;
  }
  tr:last-child td:first-child {
    @apply rounded-bl-md;
  }
  tr:last-child td:last-child {
    @apply rounded-br-md;
  }
}
.disable-copy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input[type='checkbox']:focus {
  @apply focus:outline-none focus:ring-0 focus:ring-offset-0;
}
.diagonal-line {
  background: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgb(242, 71, 58) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}
.question-index {
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

.first-letter-capitalize:first-letter {
  text-transform: capitalize;
}


.faq-icon{
  height: 18px;
  width: 18px;
}


.rc-slider-handle {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: none;

}

.rc-slider:hover .rc-slider-handle {
  opacity: 1;
}

.rc-slider-step {
  cursor: pointer;

}
.rc-slider-rail{
  background-color: #65676A;
  height: 2px;
}


.video-react .video-react-text-track-display {
  position: absolute;
  bottom: 100px;
  left: 100px;
}
.hide-slider-value .rc-slider-mark-text {
  display: none;
}

.rc-slider-track{
  height: 2px;
}

.rc-slider-dot{
  border-radius: 0px;
  width: 6px;
  height: 2px;
  bottom: 2px;
  background: #FFC107;
  border: 1px solid #FFC107;
}

.interview-slider-dots .rc-slider-dot {
  border-radius: 0px;
  width: 4px;
  height: 2px;
  bottom: 2px;
  background: #202020;
  border: 1px solid #202020;
}

.volume-slider .rc-slider-track{
  height: 2px;
  background-color: white;
}

.volume-slider .rc-slider-rail{
  background-color: #65676A;
  height: 2px;
}

input[id^="react-select-"] {
  @apply focus:outline-none focus:border-transparent focus:ring-0;
}

.bond-option-text p {
  margin: 2px;
  display: flex;
  align-items: center;
}