.staff-tool {
    position: relative;
}
.staff-tool-container {
    position: absolute;
    background: #fff;
    width: 200%;
    left: -70px;
    top: 135%;
    border: 2px solid gray;

    .staff-tool-body {
        z-index: 2;
        display: flex;
        flex-direction: column;

        .staff-tool-list {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                width: 100%;
                height: 100%;
                padding: 10px;
                font-size: 0.9em;
                background-color: Transparent;
                background-repeat: no-repeat;
                border: none;
                cursor: pointer;
                position: relative;
                &::after {
                    content: "";
                    width: 80%;
                    height: 1px;
                    background-color: gray;
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                &:last-child::after {
                    display: none;
                }
            }
            button:hover {
                color: #fff;
            }
        }
        .staff-tool-list:hover {
            background-color: #006daa;
            color: #fff;
        }
    }
}
@media screen and (max-width: 600px) {
    .staff-tool {
        display: none;
    }
}
