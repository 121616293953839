@mixin no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}

@mixin for-below-desktop-only {
    @media (max-width: 1199px) { @content; }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}
