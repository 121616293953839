div.dialogue {
  width: 580px;
  background-color: $primary-bar-blue;
  @apply absolute z-20 max-w-full text-white left-0 top-1/4 sm:top-1/2 sm:left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  div.dialogue-titlebar {
    height: 34px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 8px;
    cursor: move;

    div.dialogue-title {
     @apply text-xs sm:text-base;
    }

    div.dialogue-close-button {
      margin-right: -4px;
      cursor: default;
    }
  }

  div.dialogue-body {
    display: flex;
    padding: 20px 0;

    div.dialogue-body-icon-container {
      flex-basis: 70px;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
    }

    div.dialogue-body-content {
      margin-right: 20px;
      line-height: 18px;
      // font-size: 16px;
    }
  }

  div.dialogue-actions {
    margin-bottom: 17px;
    margin-top: -7px;
    display: flex;
    justify-content: center;

    > div.dialogue-action-button {
      margin: 3px;
    }

    div.dialogue-action-button {
      border: 1px solid white;
      height: 27px;
      padding: 0 12px;
      display: flex;
      align-items: center;

      &:hover {
        color: $ucat-warning-yellow;
      }
    }
  }
}

.dialogue-button {
  border: 1px solid white;
  height: 27px;
  padding: 0 12px;
  display: flex;
  align-items: center;

  &:hover {
    color: $ucat-warning-yellow;
  }
}

.dialogue-body-icon-container {
  flex-basis: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}
