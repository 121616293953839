@font-face {
  font-family: 'MedEntryCalculatorDisplay';
  src: local('MedEntryCalculatorDisplay'),
    url('../assets/fonts/d7mono.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

#CalculatorContainerId {
  position: absolute;
  z-index: 9;
  top: 10px;
  left: 10vw;
  min-height: 457px;  // 20% increase from 381px
  overflow-y: hidden;
  #calc-pad-header {
    cursor: move;
    z-index: 10;
    background-color: #0d6daa;
    color: #fff;
    border: 6px solid #0d6daa;
  }
  .header-body {
    display: flex;
    justify-content: space-between;

    .close-btn {
      cursor: pointer;
      background-position: 'left -2467px';
      &:hover {
        background-position: 'left -2508px';
      }
    }
  }
}

.calculator-container {
  padding: 18px 0 0 0;
  background: #507abd;
  width: 280px;  // 20% increase from 233px
  height: 456px;  // 20% increase from 380px
  margin-left: 4.2px;
  border-radius: 4.8px;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  
  .keypad-external-outliner {
    border-radius: 4.8px;
    padding: 7.2px;
    margin: 43px 14.4px 14.4px 14.4px;
    width: auto;
    @apply border-2 border-gray-700;
  }
  
  #calcTable {
    width: 100%;
    border-radius: 4.8px;
    padding: 1px;
  }

  .display-border {
    padding: 2.4px;
    background-color: #c3d1ba;
    border-radius: 2.4px;
    width: fit-content;
    margin: auto;
  }

  .display-screen {
    display: flex;
    background-color: white;
    width: 240px;  // 20% increase from 200px
    height: 46px;  // 20% increase from 38px

    .accessary-display {
      position: relative;
    }

    input {
      outline: none;
      border: 0;
      text-align: right;
    }
    
    input.memory-screen {
      height: 14.4px;
      width: 17px;
      font-size: 17px;
      padding: 0 0 2.4px;
      text-align: center;
      
      &:focus {
        outline: none !important;
      }
    }
    
    input.calcDisplay {
      width: 100%;
      height: 46px;
      font-size: 38px;  // 20% increase from 32px
      font-family: 'MedEntryCalculatorDisplay';
      color: black;
      -webkit-text-fill-color: rgba(0, 0, 0, 1);
      -webkit-opacity: 1;
      outline: none !important;
      &:focus {
        outline: none !important;
      }
    }
  }

  td {
    height: 100%;
    width: 46px;  // 20% increase from 38px
    font-size: 14.4px;
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 2.4px;
    position: relative;
    padding: 4.8px;
    
    .input-container {
      width: 46px;
      height: 31px;  // 20% increase from 26px
      font-size: 14.4px;
      box-shadow: none;
      outline: none;
      border: none;
      border-radius: 2.4px;
      position: relative;
      background-color: #fff;
      
      &.calcOrangeBtn {
        background-color: orange;
      }
      &.calcRedBtn {
        background-color: #de1f2a;
        color: white;
        border: 1px solid #4a4a4a;
      }
      &.equalSignContainer {
        height: 76px;  // 20% increase from 63px
      }
      &:hover {
        opacity: 0.9;
      }
      
      img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 46px;
        opacity: 0.05;
        filter: brightness(0);
      }
      
      input[type='button'] {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 17px;
        font-weight: 600;
        box-shadow: none;
        outline: none;
        border: none;
        background-color: inherit;
        color: #0f0f0f;
        padding-left: 4.8px;
        &:hover {
          opacity: 0.9;
        }
        
        &.calcRedBtn {
          font-size: 14.4px;
          font-weight: 400;
          background-color: inherit;
          color: white;
          padding-left: 4.8px;
        }
        &.calcSkillTrainerRedBtn {
          font-weight: 400;
          background-color: inherit;
          color: white;
          padding-left: 4.8px;
        }
        &.redBtns {
          font-size: 14.4px;
          font-weight: 400;
          background-color: inherit;
          color: white;
          padding-left: 4.8px;
        }
      }
    }

    .input-container:active {
      background-color: orange;
    }

    &:last-child {
      div.calcRedBtn {
        margin-left: 14.4px;
      }
      div.calcOrangeBtn {
        margin-left: 14.4px;
      }
    }
  }

  .company-info {
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: space-evenly;
    
    .company-name {
      font-size: 13.2px;
      white-space: nowrap;
      margin-top: 7.2px;
      font-family: sans-serif;
      color: white;
    }
    .model {
      font-size: 24px;
      white-space: nowrap;
      font-weight: bold;
      font-family: sans-serif;
      color: white;
    }
  }

  .compensation-margin {
    border-top: 2.4px solid #444;
    margin: 2.4px 0 6px;
  }
}

.calc-backgroud-color2 {
  background-color: orange;
}
