@media screen and (min-width: $ucat-platform-nowrap-width) {
  div.question-full {
    width: 100%;
  }

  div.question-left {
    flex: 0 0 60%;
    // height: 'calc(100vh - 125px)';
    border-right: 4px solid $primary-bar-blue;
  }

  div.question-right {
    display: block;
    flex: 1;
    border-left: 4px solid $primary-bar-blue;
  }
}

table.multiple-choice-question-answer-table {
  width: 100%;
  max-width: 800px;

  tr {
    cursor: default;
    p {
      margin: 0;
      padding: 0;
    }

    td {
      padding-bottom: 15px;

      &.popularity-bar-column {
        width: $option-popularity-bar-width;

        @include for-below-desktop-only {
          width: $option-popularity-bar-width-mobile;
        }
      }

      &.answer-number-column {
        width: 43px;
      }

      &.answer-select-column {
        width: 19px;
      }
    }

    th {
      padding-bottom: 5px;
    }

    td,
    th {
      &.percentage-chosen-column {
        padding-left: 5px;
        text-align: left;

        .percentage-chosen-rounded {
          display: none;
        }

        .percentage-chosen-full {
          display: inline;
        }

        @include for-phone-only {
          .percentage-chosen-rounded {
            display: inline;
          }

          .percentage-chosen-full {
            display: none;
          }
        }
      }
    }

    input[type='radio'] {
      margin-left: 0;
      margin-right: 2px;
    }

    td.answer-correctness-badge-column {
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;
    }
  }

  // @import "question-option-popularity";
}
