$primary-bar-blue: #006daa;
$secondary-bar-blue: #80aee1;
$ucat-warning-yellow: #ff0;
$ucat-platform-nowrap-width: 1200px;
$drag-drop-darker-shade: #bab1b1;
$drag-drop-lighter-shade: #dfdfdf;
$drag-drop-glow-colour: #579af8;
$option-popularity-bar-width: 150px;
$option-popularity-bar-width-mobile: 100px;

$timing-trainer-bar-width: 240px;
$timing-trainer-bar-height: 28px;
$timing-trainer-border-radius: 3px;
$timing-trainer-border-header-colour: rgb(53, 133, 119);
$timing-trainer-overtime-colour: rgb(193, 99, 78);
$timing-trainer-default-colour: #fff;
$timing-trainer-empty-progress-colour: rgb(204, 210, 208);
$timing-trainer-progress-fill-colour: rgb(79, 193, 162);
