.draggable {
  position: absolute;
  .draggable-header {
    cursor: move;
    z-index: 10;
    background-color: #0d6daa;
    color: #fff;
    height: 42px;
    padding: 4px 0px 4px 8px;
    border-bottom: 1px solid white;
  }
  .disabled {
    border-color: #0d6daa;
    border-bottom-color: #fff;
    @apply bg-blue-400 border;
  }
  .draggable-header-body {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;

    .close-btn {
      cursor: pointer;
      margin-right: 3px;
    }
    .calculator-close {
      img {
        margin-right: 0px !important;
        background-position: left -2469px;
        &:hover {
          background-position: left -2509px;
        }
      }
    }
    .draggable-header-title {
      display: flex;
      align-items: center;
    }
    img {
      // width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    .draggable-sprite-icon {
      width: 14px;
      max-width: 100%;
      background-image: url('../assets/_all-sprites.png');
      vertical-align: middle;
      background-color: #fff;
      border-radius: 4px;
    }
  }
  .draggable-resize-body {
    position: relative;
    display: flex;
    justify-content: space-between;

    .draggable-resize-arrow {
      position: absolute;
      width: 10px;
      height: 10px;
      top: -15px;
      &-left {
        left: 0;
        cursor: ne-resize;
        background: url('../assets/sw-resizer.png');
        //   no-repeat;
      }
      &-right {
        right: 0;
        cursor: nw-resize;
        background: url('../assets/se-resizer.png');
        //   no-repeat;
      }
    }
  }
}
