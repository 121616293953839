@mixin grab-cursor {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/openhand.cur'),
    all-scroll;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

@mixin grabbing-cursor {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'),
    all-scroll;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

// Anything different in the presets is set here:
@media screen and (max-width: 767px) {
  .drag-drop-controls-area {
    &.yes_no_dd_preset {
      .drag-drop-draggable-item {
        width: 80px !important;
      }
    }
    &.sj_2022_dd_preset {
      .drag-drop-draggable-item {
        width: 139px !important;
      }
    }
    &.sj_matching_dd_preset {
      .drag-drop-draggable-item {
        width: 599px !important;
      }
    }
  }
}
.drag-drop-controls-area {
  display: inline-flex;

  &.yes_no_dd_preset {
    flex-direction: row;

    .drag-drop-statement-row-container .drag-drop-statement {
      width: 510px;
      padding: 10px;
    }

    .drag-drop-draggable-item {
      width: 80px;
      padding: 10px;
    }

    .drag-drop-dropzone {
      min-height: 37px;
      min-width: 82px;
    }

    .drag-drop-answer-controls {
      padding: 10px;
    }

    .drag-drop-answers-container {
      padding: 46px 26px 0px 26px;
    }
  }

  &.sj_matching_dd_preset {
    flex-direction: column;
    padding-top: 20px;

    .drag-drop-statement-row-container .drag-drop-statement {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 10px;
    }

    .drag-drop-draggable-item {
      width: 600px;
      padding: 15px 10px;
      &:hover {
        cursor: pointer;
      }
    }

    .drag-drop-dropzone {
      width: 600px;
    }

    .drag-drop-answer-controls {
      padding: 20px;
      min-height: 240px;
    }

    .drag-drop-answers-container {
      text-align: center;
      padding-top: 26px;
    }

    .drag-drop-answer-controls.droppable-hovering-over {
      background-color: $drag-drop-darker-shade;
    }
  }
  &.sj_2022_dd_preset {
    flex-direction: row;

    .drag-drop-statement-row-container .drag-drop-statement {
      width: 510px;
      padding: 10px;
    }

    .drag-drop-draggable-item {
      width: 140px;
      padding: 10px;
    }

    .drag-drop-dropzone {
      width: 142px;
    }

    .drag-drop-answer-controls {
      padding: 10px;
    }

    .drag-drop-answers-container {
      @apply flex items-center justify-center pl-6 mb-4;
    }
  }
}
.drag-drop-statement-row-container {
  @media (hover: hover) and (pointer: fine) {
    @apply py-0 my-0 mb-4 mr-0;
  }
  display: flex;
  @apply py-2 my-4 sm:my-7;
  .drag-drop-statement {
    border: 1px solid black;
    margin-right: 20px;
    text-align: center;
  }

  .drag-drop-dropzone {
    border: 1px solid black;
    background: $drag-drop-darker-shade;
    text-align: center;

    .drag-drop-draggable-item {
      border: none;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: -1px 0px -1px -1px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.drag-drop-answer-controls {
  border: 1px solid black;
  background: $drag-drop-lighter-shade;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  justify-content: center;
}

.drag-drop-draggable-item {
  border: 1px solid black;
  background-color: white;
  padding: 10px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  &.invisible-unselectable {
    z-index: 20;
    opacity: 0;
    cursor: pointer;
  }
}
#drag {
  @include grab-cursor;
  &:active {
    @include grabbing-cursor;
  }
}

// .droppable-hovering-over {
//   &:hover {
//     box-shadow: 0 0 5px 5px $drag-drop-glow-colour;
//   }
// }
